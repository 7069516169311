import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getSpecificationsSubSections,
  invalidationKeySpecificationSelector,
  isLoadingSpecificationSelector,
  specificationSubSectionsSelector,
} from "redux/modules/common/building/materials/specification";

import SubSection from "../../components/SubSection";
import { SPECIFICATION } from "components/pages/Materials/constants";
import { MaterialsCreateSectionRow } from "components/pages/Materials/widgets/MaterialsCreateSection/MaterialsCreateSection";

import Spinner from "shared/ui/atoms/Spinner/Spinner";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { useTypedParams } from "utils/hooks/useTypedParams";

import materialsIcon from "images/icons/navigation/materials.svg";

interface IProps {
  canEdit: boolean;
}

const SpecificationSubSections = ({ canEdit }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sectionId, objectId } = useTypedParams();
  const isLoading = useSelector(isLoadingSpecificationSelector);
  const specificationSubSections = useSelector(specificationSubSectionsSelector);
  const invalidationKey = useSelector(invalidationKeySpecificationSelector);

  useEffect(() => {
    dispatch(getSpecificationsSubSections(objectId, SPECIFICATION, sectionId));
  }, [sectionId, objectId, invalidationKey]);

  return (
    <div>
      <BackNavigationBar
        title={specificationSubSections?.name}
        onBack={() => history.push(`/objects/${objectId}/materials/specification`)}
      />{" "}
      <MaterialsCreateSectionRow type="subSection" />
      {isLoading && <Spinner isFixed />}
      {specificationSubSections?.subsections_count === 0 && <EmptyPlaceholder img={materialsIcon} />}
      {specificationSubSections?.subsections?.map((subSection) => (
        <div key={subSection.id} /* onClick={() => setAddPosition(true)} */>
          <SubSection
            key={subSection.id}
            objectId={objectId}
            data={subSection}
            //setPositionEditModalOpen={setPositionEditModalOpen}
            blockOpenExpenditure={() => {
              history.push(`/objects/${objectId}/materials/specification/${sectionId}/${subSection.id}`);
            }}
            canEdit={canEdit}
            //count={subSection.products_count}
          />
        </div>
      ))}
    </div>
  );
};

export default SpecificationSubSections;
