//TODO переписать всю эту хуйню, корневой файл страницы не должен содержать логику,
//  а только лишь конфигурировать внутренние компоненты
import { Location } from "history";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import { Action, compose } from "redux";

import { userSelector } from "redux/modules/_TODO/auth";
import { getDetail, objectDetailSelector } from "redux/modules/common/building/object/nowObject";
import {
  clearIntervals,
  clearSelecteds,
  selectedProductsSelector,
  setLoadingIntervals,
} from "redux/modules/common/building/process";
import { loadSections, resetSectionsAction } from "redux/modules/common/building/sections/sections";

import ExpenditureGroupModal from "../../UI/_TODO/ExpenditureGroupModal/ui/ExpenditureGroupModal";
import EstimateIndicators from "./components/EstimateIndicators/EstimateIndicators";
import ProjectHeader from "./components/ProjectHeader/ProjectHeader";
import ProjectSubsectionsHeader from "./components/ProjectHeader/ProjectSubsectionsHeader";
import ProjectNavbar from "./components/ProjectNavbar/ProjectNavbar";
import ProjectSharingBar from "./components/ProjectSharingBar/ProjectSharingBar";
import Section from "./components/Section/Section";
import SubSection from "./components/SubSection/SubSectionContainer";
import { useSectionPlan } from "./components/SubSection/useSectionPlan";
import ForbiddenPage from "app/routes/components/ForbiddenPage/ForbiddenPage";
import Blueprints from "pages/Estimate/Blueprints/Blueprints";
import ImportDocumentation from "pages/Handler/ui/ProHandler/components/ImportExtraordinaryDocumentation/ImportDocumentation";
import { ESTIMATE_STATES_IDS } from "pages/Handler/ui/ProHandler/constants";

import { CreateOutOfEstimateSection } from "../../../features/CreateOutOfEstimateSection";
import KS2Page from "../KSPage/KS2Page";
import KS3Page from "../KSPage/KS3Page";
import { forbiddenTestId, templateTestId } from "./tests/buildingMocks";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { DEFAULT_BUILDING_FILTERS, DOCUMENTATION } from "./constants";
import { PLAN } from "./constants";
import { TabsEnum } from "./enums";
import { IExpenditureTypesFiltersInBuilding, ISelectedProduct, IbuildingSubsection } from "./types";

import { _TEMP_useBuildingPage } from "./_TEMP_hooks/index";
import { useOpenPlanApprove } from "./hooks/useOpenPlanApprove";

import estimateIcon from "images/icons/navigation/estimateIcon.svg";

import styles from "./Building.module.scss";

export interface IBuildingPropsPermissions {
  canViewPlan: boolean;
  canViewProgress: boolean;
  canSharePlan: boolean;
  canShareProgress: boolean;
  canAddFiles: boolean;
  canDeleteFiles: boolean;
  canAddPlan: boolean;
  canAddProgress: boolean;

  canViewDrawings: boolean;
  canAddDrawingSets: boolean;
  canEditDrawingSets: boolean;
  canDeleteDrawingSets: boolean;
  canAddDrawings: boolean;
  canEditDrawings: boolean;
  canDeleteDrawings: boolean;
}

export interface IBuildingProps {
  editable?: boolean;
  permissions: IBuildingPropsPermissions;
  location: Location;
  match: RouteComponentProps["match"];
}

const Building = ({ editable = true, permissions, location, match }: IBuildingProps) => {
  const { buildingId, dispatch, history } = _TEMP_useBuildingPage.commonUtils();

  const objectInfo = useSelector(objectDetailSelector);
  const userInfo = useSelector(userSelector);

  const selectedProducts: ISelectedProduct[] = useSelector(selectedProductsSelector);

  const { isNeedToOpenPlan, openSectionId, openSubsectionId } = useOpenPlanApprove();

  const [selectedSection, setSelectedSection] = useState<IbuildingSubsection | null>();
  const [filter, setFilter] = useState<IExpenditureTypesFiltersInBuilding | null>(DEFAULT_BUILDING_FILTERS);
  const [openedParentId, setOpenedParentId] = React.useState(0);
  const [isIndicatorsOpen, setIsIndicatorsOpen] = useState(true);

  useEffect(() => {
    dispatch(getDetail(buildingId));
    dispatch(clearSelecteds());
    setSelectedSection(null);
    compose<Action>(dispatch, loadSections)({ estimateStateId: ESTIMATE_STATES_IDS.PRODUCTION, buildingId });
    return () => {
      dispatch(clearIntervals());
      dispatch(resetSectionsAction());
    };
  }, [buildingId]);

  const { sectionsAreLoading, sections, isSectionsExist } = _TEMP_useBuildingPage.sections();

  const { activeTabId, handleChangeActiveTab } = _TEMP_useBuildingPage.navigation({
    isNeedToOpenPlan,
    permissions,
    buildingId,
    match,
    location,
  });

  const {
    date,
    setDate,
    startOfMonthFormattedDate,
    endOfMonthFormattedDate,
    selectingMonthsCount,
    setSelectingMonthsCount,
  } = _TEMP_useBuildingPage.dates({
    isNeedToOpenPlan,
  });

  const { planData, refetchSectionPlan } = useSectionPlan(openedParentId || -1, !!openedParentId);

  const tabs = _TEMP_useBuildingPage.tabs(permissions, styles);

  const { ks2Id, ks3Id } = _TEMP_useBuildingPage.ks();

  const { subSectionsForDisplay, loadAndSetSubSection, loadSubSection, isLoadingSubSections } =
    _TEMP_useBuildingPage.subSections({
      selectedSection,
      openedParentId,
      sections,
      isNeedToOpenPlan,
      editable,
      setSelectedSection,
      setOpenedParentId,
      openSectionId,
    });

  const {
    onAddOutOfEstimateClick,
    isCreateOutOfEstimateSectionVisible,
    onCreateOutOfEstimateSectionCallback,
    canAddOutOfEstimate,
  } = _TEMP_useBuildingPage.outOfEstimate({
    isLoadingSubSections,
    selectedSection,
    activeTabId,
    setSelectedSection,
    editable,
  });

  const { sharingCallback, isShowSharedButtons, approveSharingHandler, declineSharingHandler, isShared, canSharing } =
    _TEMP_useBuildingPage.sharing({
      buildingId,
      loadAndSetSubSection,
      selectedSection,
      objectInfo,
      userInfo,
      permissions,
      activeTabId,
    });

  const {
    handleCloseGroupModal,
    handleOpenCreateGroupModal,
    isGroupingPending,
    handleCreateGroup,
    canGroup,
    isShowGroupModal,
  } = _TEMP_useBuildingPage.groups({
    selectedProducts,
    activeTabId,
  });

  const { handleBackClick, canCheck, backPath, canAddSectionPlan, canAddSupersectionPlan } =
    _TEMP_useBuildingPage.uiConfig({
      setSelectedSection,
      canGroup,
      canSharing,
      activeTabId,
    });

  if ((!permissions.canViewPlan && !permissions.canViewProgress && !permissions.canViewDrawings) || !activeTabId) {
    return (
      <TemplateBase>
        <ForbiddenPage dataTestId={forbiddenTestId} />
      </TemplateBase>
    );
  }

  if (activeTabId === DOCUMENTATION) {
    return (
      <Blueprints
        permissions={permissions}
        activeId={activeTabId}
        tabs={tabs as any}
        onTabChange={(tab: TabsEnum) => {
          if ((tab as string) === activeTabId) return;
          handleChangeActiveTab(tab);
        }}
      />
    );
  }

  const showAloneAddSectionButton = !isSectionsExist && activeTabId === "plan";

  const refreshSections = () => {
    compose<Action>(dispatch, loadSections)({ estimateStateId: ESTIMATE_STATES_IDS.PRODUCTION, buildingId });
  };

  return (
    <>
      {ks2Id && <KS2Page />}
      {ks3Id && <KS3Page />}
      <TemplateBase contentClassName={styles.outerTemplate} dataTestId={templateTestId}>
        <div className={styles.head}>
          <ProjectNavbar
            isTabs={!isShared}
            onTabChange={(tab: TabsEnum) => {
              if (tab === activeTabId) return;
              dispatch(clearIntervals());
              dispatch(setLoadingIntervals(true));
              handleChangeActiveTab(tab);
            }}
            activeTab={activeTabId}
            filters={filter}
            setFilters={setFilter}
            timeSliderDate={date}
            onChangeTimeSlider={setDate}
            selectingMonthsCount={selectingMonthsCount}
            setSelectingMonthsCount={setSelectingMonthsCount}
            permissions={permissions}
            /* @ts-ignore */
            location={location}
            match={match}
            history={history}
            _TEMP_hidePlan={false}
          />
          {showAloneAddSectionButton && isCreateOutOfEstimateSectionVisible && (
            <CreateOutOfEstimateSection
              parentId={selectedSection?.id}
              onCreateCallback={onCreateOutOfEstimateSectionCallback}
            />
          )}
          {isSectionsExist && (
            <>
              <EstimateIndicators id={buildingId} isOpen={isIndicatorsOpen} setIsOpen={setIsIndicatorsOpen} />
              <ProjectHeader
                isExists={!Boolean(selectedSection)}
                back={backPath}
                name={objectInfo?.name}
                isShared={isShared}
                isShowSharedButtons={isShowSharedButtons}
                onApproveSharing={approveSharingHandler}
                onDeclineSharing={declineSharingHandler}
                isSharedStatusTransferred={objectInfo?.shared_status === "transferred"}
              />
              <ProjectSubsectionsHeader
                onBackClick={handleBackClick}
                isExists={Boolean(selectedSection)}
                projectName={objectInfo?.name}
                isShared={isShared}
                subSection={selectedSection}
                onApproveSharing={approveSharingHandler}
                onDeclineSharing={declineSharingHandler}
              />
              <ProjectSharingBar
                handleOpenModal={handleOpenCreateGroupModal}
                isExists={canSharing || canGroup}
                canSharing={canSharing}
                canGroup={canGroup}
                canAddOutOfEstimate={canAddOutOfEstimate}
                onAddOutOfEstimateClick={onAddOutOfEstimateClick}
                sharingCallback={sharingCallback}
                filters={filter}
                isInsideSection={!!selectedSection}
                canImport={activeTabId === "plan"}
                refreshSections={refreshSections}
              />
            </>
          )}
        </div>
        {isSectionsExist ? (
          <div className={styles.container}>
            {isCreateOutOfEstimateSectionVisible && (
              <CreateOutOfEstimateSection
                parentId={selectedSection?.id}
                onCreateCallback={onCreateOutOfEstimateSectionCallback}
              />
            )}
            {isShowGroupModal && (
              /* @ts-ignore */
              <ExpenditureGroupModal
                isCreation
                isShowButtons
                isOpen={isShowGroupModal}
                onClose={handleCloseGroupModal}
                handleCreate={handleCreateGroup}
                creationExpenditures={selectedProducts}
                isPending={isGroupingPending}
              />
            )}
            {isLoadingSubSections && <Spinner />}
            {!selectedSection &&
              sections &&
              !isLoadingSubSections &&
              sections?.map((section, index) => {
                const canShareSection = canSharing && !section.providers?.length;
                if (section.hidden_status === 1) return;
                return (
                  <div
                    key={section.id}
                    onClick={() => {
                      loadSubSection(section.id);
                      setOpenedParentId(section.id);
                    }}
                  >
                    {/* @ts-ignore */}
                    <Section
                      section={section}
                      canSharing={canShareSection}
                      canCheck={canCheck}
                      activeTab={activeTabId}
                      filters={filter}
                      dateEnd={endOfMonthFormattedDate}
                      dateStart={startOfMonthFormattedDate}
                      permissions={permissions}
                      canAddPlan={canAddSupersectionPlan}
                      canViewPlanData={activeTabId === PLAN}
                      /* сколько еще refetch здесь будет добавляться? */
                      refetchLsrPlan={refetchSectionPlan}
                      onRefetch={() => {
                        compose<Action>(
                          dispatch,
                          loadSections
                        )({ estimateStateId: ESTIMATE_STATES_IDS.PRODUCTION, buildingId });
                      }}
                    />
                  </div>
                );
              })}
            {selectedSection && (
              <>
                {subSectionsForDisplay.length > 0 &&
                  subSectionsForDisplay?.map((subSection, index) => {
                    return (
                      <SubSection
                        objectId={+buildingId}
                        section={subSection}
                        editable={editable}
                        /* @ts-ignore */
                        activeTab={activeTabId}
                        date_start={startOfMonthFormattedDate}
                        date_end={endOfMonthFormattedDate}
                        isShared={isShared}
                        isIndicatorsOpen={isIndicatorsOpen}
                        key={subSection.id}
                        filters={filter!}
                        canSharing={canSharing}
                        canCheck={canCheck}
                        permissions={permissions}
                        canAddPlan={canAddSectionPlan}
                        lsrPlanData={planData!}
                        isKSHidden={subSection.is_default}
                        /* @ts-ignore */
                        isDefaultOpened={+openSubsectionId === +subSection.id}
                        onRefetch={() => {
                          loadSubSection(selectedSection.id);
                        }}
                      />
                    );
                  })}
                {subSectionsForDisplay.length === 0 && <div className={styles.errorMessage}>Нет разделов</div>}
              </>
            )}
          </div>
        ) : sectionsAreLoading ? (
          <div data-testid="spinner">
            <Spinner />
          </div>
        ) : (
          <EmptyPlaceholder img={estimateIcon}>
            {showAloneAddSectionButton && (
              <div className={styles.placeholderBtns}>
                <ButtonBase onClick={onAddOutOfEstimateClick} primary>
                  Добавить раздел
                </ButtonBase>
                <ImportDocumentation
                  refreshSections={refreshSections}
                  renderBtn={(onOpen) => (
                    <ButtonBase onClick={onOpen} primary>
                      Импорт
                    </ButtonBase>
                  )}
                />
              </div>
            )}
          </EmptyPlaceholder>
        )}
      </TemplateBase>
    </>
  );
};

export default Building;
